<template>
  <a-card :bordered="false" class="card-area">
    <div class="page-form-advanced-search">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :md="5" :sm="24">
            <a-form-item
              label="项目"
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 18, offset: 1 }"
            >
            <a-input v-model="queryParams.projectName" />
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24">
            <a-form-item
              label="合同号"
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 18, offset: 1 }"
            >
            <a-input v-model="queryParams.contractNo" />
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24">
            <a-form-item
              label="工单号"
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 18, offset: 1 }"
            >
              <a-input v-model="queryParams.taskNo" />
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24">
            <a-form-item
              label="类型"
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 18, offset: 1 }"
            >
              <a-select
                show-search
                placeholder="选择工单类型"
                v-model="queryParams.taskType"
                style="width: 100%"
              >
                <a-select-option
                  v-for="t in taskType"
                  :key="t.keyy"
                  :value="t.keyy"
                  >{{ t.valuee }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12"> </a-col>
          <a-col :span="12" :style="{ textAlign: 'right' }">
            <a-button type="primary" @click="search">查询</a-button>
            <a-button style="margin-left: 8px" @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div>
      <div class="operator">
        <a-button
          style="margin-left: 50px"
          @click="exportExcel"
          v-hasPermission="['project:export']"
          >导出Excel</a-button
        >
      </div>
      <!-- 表格区域 -->
      <a-table
        ref="TableInfo"
        :columns="columns"
        :rowKey="(record) => record.taskId"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        :rowSelection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        @change="handleTableChange"
        bordered
        :scroll="{ x: 900 }"
      >
        <template slot="engineers" slot-scope="text, record">
          <a-tag
            :color="item.isPrime == '1' ? `green` : ``"
            v-for="(item, index) of record.taskAssigns"
            :key="index"
          >
            {{ item.engineer.username }}
          </a-tag>
        </template>
        <template slot="taskLog" slot-scope="text, record">
          <a-icon
            v-if="record.taskLogs != undefined || record.taskReceipts != undefined"
            type="eye"
            theme="twoTone"
            twoToneColor="#42b983"
            @click="showTaskLog(record)"
            title="查看"
          ></a-icon>
          <a-badge v-else status="warning" text="无"></a-badge>
        </template>
        <template slot="taskLeftover" slot-scope="text, record">
          <a-icon
            v-if="text == 1"
            type="eye"
            theme="twoTone"
            twoToneColor="#42b983"
            @click="showLeftover(record)"
            title="查看"
          ></a-icon>
          <a-badge v-else status="success" text="无"></a-badge>
        </template>
        <template slot="taskReceipt" slot-scope="text, record">
          <a-icon
            v-if="record.taskReceipts != undefined"
            type="eye"
            theme="twoTone"
            twoToneColor="#42b983"
            @click="showReceipt(record)"
            title="查看"
          ></a-icon>
          <a-badge v-else status="warning" text="无"></a-badge>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a-icon
            v-hasPermission="['task:update']"
            type="setting"
            theme="twoTone"
            twoToneColor="#4a9ff5"
            @click="edit(record)"
            title="修改项目"
          ></a-icon>
          <a-badge
            v-hasNoPermission="['task:update']"
            status="warning"
            text="无权限"
          ></a-badge>
        </template>
      </a-table>
      <import-result
        @close="handleClose"
        :importData="importData"
        :errors="errors"
        :times="times"
        :importResultVisible="importResultVisible"
      >
      </import-result>
      <!-- 新增工单 -->
      <task-add
        @close="handleTaskAddClose"
        @success="handleTaskAddSuccess"
        :taskType="this.taskType"
        :taskAddVisiable="taskAddVisiable"
      >
      </task-add>
      <!-- 修改工单 -->
      <task-edit
        ref="taskEdit"
        @close="handleTaskEditClose"
        @success="handleTaskEditSuccess"
        :status="this.status"
        :taskType="this.taskType"
        :taskEditVisiable="taskEditVisiable"
      >
      </task-edit>
      <!-- 查看遗留问题 -->
      <task-leftover
        :taskData="taskData"
        :leftoverVisiable="leftoverVisiable"
        @close="handleLeftoverClose"
      >
      </task-leftover>
      <!-- 查看用户回执单信息 -->
      <task-receipt
        :taskData="taskData"
        :receiptVisiable="receiptVisiable"
        @close="handleReceiptClose"
      >
      </task-receipt>
      <!-- 查看服务日志 -->
      <task-log
        :taskData="taskData"
        :taskType="this.taskType"
        :logVisiable="logVisiable"
        @close="handleTaskLogClose"
      >
      </task-log>
    </div>
  </a-card>
</template>
<script>
import taskAdd from "./taskAdd";
import taskEdit from "./taskEdit";
import ImportResult from "./ImportResult";
import taskLeftover from "./taskLeftover";
import taskReceipt from "./taskReceipt";
import taskLog from "./taskLog";
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "taskService",
  components: {
    taskAdd,
    taskEdit,
    taskLeftover,
    taskReceipt,
    taskLog,
    ImportResult,
  },
  data() {
    return {
      fileList: [],
      importData: [],
      times: "",
      errors: [],
      uploading: false,
      importResultVisible: false,
      dataSource: [],
      selectedRowKeys: [],
      paginationInfo: null,
      pagination: {
        pageSizeOptions: ["10", "20", "30", "40", "100"],
        defaultCurrent: 1,
        defaultPageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) =>
          `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`,
      },
      queryParams: { projectId: "", isTaskService: 1 },
      taskAddVisiable: false,
      taskEditVisiable: false,
      leftoverVisiable: false,
      receiptVisiable: false,
      logVisiable: false,
      loading: false,
      taskData: {},
    };
  },
  computed: {
    ...mapState({
      status: (state) => state.dict.dicts.task_status,
      taskType: (state) => state.dict.dicts.task_task_type,
    }),
    columns() {
      return [
        {
          title: "工单号",
          fixed: "left",
          dataIndex: "taskNo",
          width: 130,
        },
        {
          title: "日志",
          width: 80,
          fixed: "left",
          dataIndex: "taskLog",
          scopedSlots: { customRender: "taskLog" },
        },
        {
          title: "项目信息",
          children: [
            {
              title: "合同号",
              dataIndex: "project.contractNo",
              width: 150,
            },
            {
              title: "名称",
              dataIndex: "project.name",
              width: 200,
            },
            {
              title: "型号",
              dataIndex: "project.productVersion",
              width: 80,
            },
            {
              title: "数量",
              dataIndex: "project.amount",
              width: 70,
            },
            {
              title: "SAP订单",
              dataIndex: "project.sapNo",
              width: 110,
            },
            {
              title: "项目地址",
              dataIndex: "projectAddress",
              customRender: (text, row, index) => {
                const province = row.project.province || ''
                const city = row.project.city || ''
                return province  + ' ' + city
              },
              width: 150,
            },
          ],
        },
        {
          title: "派单信息",
          children: [
            {
              title: "服务工程师",
              dataIndex: "engineers",
              width: 150,
              scopedSlots: { customRender: "engineers" },
            },
            {
              title: "工单类型",
              dataIndex: "taskType",
              customRender: (text, row, index) => {
                for (let _index in this.taskType) {
                  let key = this.taskType[_index].keyy;
                  if (text === key) {
                    let val = this.taskType[_index].valuee;
                    let color = this.taskType[_index].otherKeyy;
                    return <a-badge color={color} text={val}></a-badge>;
                  } else {
                    continue;
                  }
                }
                return text;
              },
              width: 200,
            },
            {
              title: "问题描述",
              dataIndex: "description",
              width: 200,
            },
            {
              title: "客户名称",
              dataIndex: "customer",
              width: 200,
            },
            {
              title: "客户地址",
              dataIndex: "address",
              width: 200,
              customRender: (text, row, index) => {
                let province = row.province == undefined ? '' : row.province
                let city = row.city == undefined ? '' : row.city
                let area = row.area == undefined ? '' : row.area
                let address = row.address == undefined ? '' : row.address

                return province + city + area + address;
              },
            },
            {
              title: "客户联系人",
              dataIndex: "contact",
              width: 120,
            },
            {
              title: "客户电话",
              dataIndex: "phone",
              width: 120,
            },
            {
              title: "预计开始",
              dataIndex: "beginDate",
              width: 110,
              customRender: (text, row, index) => {
                return moment(text).format("YYYY-MM-DD");
              },
            },
            {
              title: "预计结束",
              dataIndex: "endDate",
              width: 110,
              customRender: (text, row, index) => {
                return moment(text).format("YYYY-MM-DD");
              },
            },
            {
              title: "派单时间",
              dataIndex: "createTime",
              width: 160,
              customRender: (text, row, index) => {
                return moment(text).format("YYYY-MM-DD HH:mm:ss");
              },
            },
            {
              title: "接单时间",
              dataIndex: "receiveTime",
              width: 160,
              customRender: (text, row, index) => {
                return text == undefined
                  ? ""
                  : moment(text).format("YYYY-MM-DD HH:mm:ss");
              },
            },
            {
              title: "在途时间",
              dataIndex: "halfwayTime",
              width: 160,
              customRender: (text, row, index) => {
                return text == undefined
                  ? ""
                  : moment(text).format("YYYY-MM-DD HH:mm:ss");
              },
            },
            {
              title: "开始时间",
              dataIndex: "confirmTime",
              width: 160,
              customRender: (text, row, index) => {
                return text == undefined
                  ? ""
                  : moment(text).format("YYYY-MM-DD HH:mm:ss");
              },
            },
            {
              title: "结束时间",
              dataIndex: "finishedTime",
              width: 160,
              customRender: (text, row, index) => {
                return text == undefined
                  ? ""
                  : moment(text).format("YYYY-MM-DD HH:mm:ss");
              },
            },
            {
              title: "遗留问题",
              width: 100,
              dataIndex: "leftover",
              customRender: (text, row, index) => {
                return row.status == 4 ? row.leftoverProblem : "无";
              },
            },
          ],
        },
        {
          title: "服务质量",
          fixed: "right",
          width: 100,
          dataIndex: "serveStar",
          customRender: (text, row, index) => {
            return text != "0" ? text + " 星" : "未评价";
          },
        },
        {
          title: "产品质量",
          fixed: "right",
          width: 100,
          dataIndex: "productStar",
          customRender: (text, row, index) => {
            return text != "0" ? text + " 星" : "未评价";
          },
        },
        {
          title: "工程师态度",
          fixed: "right",
          width: 150,
          dataIndex: "attitudeStar",
          customRender: (text, row, index) => {
            return text != "0" ? text + " 星" : "未评价";
          },
        },
        {
          title: "工程师技术能力",
          fixed: "right",
          width: 150,
          dataIndex: "abilityStar",
          customRender: (text, row, index) => {
            return text != "0" ? text + " 星" : "未评价";
          },
        },
      ];
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    handleClose() {
      this.importResultVisible = false;
    },
    downloadTemplate() {
      this.$download("task/template", {}, "Task_ImportTemplate.xlsx");
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    handleRemove(file) {
      if (this.uploading) {
        this.$message.warning("文件导入中，请勿删除");
        return;
      }
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    handleTaskAddSuccess() {
      this.taskAddVisiable = false;
      this.$message.success("派单成功");
      this.search();
    },
    handleTaskAddClose() {
      this.taskAddVisiable = false;
    },
    add() {
      this.taskAddVisiable = true;
    },
    handleTaskEditSuccess() {
      this.taskEditVisiable = false;
      this.$message.success("修改项目成功");
      this.search();
    },
    handleTaskEditClose() {
      this.taskEditVisiable = false;
    },
    edit(record) {
      this.$refs.taskEdit.setFormValues(record);
      this.taskEditVisiable = true;
    },
    showLeftover(record) {
      this.taskData = record;
      this.leftoverVisiable = true;
    },
    showReceipt(record) {
      this.taskData = record;
      this.receiptVisiable = true;
    },
    showTaskLog(record) {
      this.taskData = record;
      this.logVisiable = true;
    },
    handleLeftoverClose() {
      this.leftoverVisiable = false;
    },
    handleReceiptClose() {
      this.receiptVisiable = false;
    },
    handleTaskLogClose() {
      this.logVisiable = false;
    },
    exportExcel() {
      let taskIds = "";
      if (this.selectedRowKeys.length) {
        taskIds = this.selectedRowKeys.join(",");
      }
      this.$export("task/excel-task-service?taskIds=" + taskIds, {
        ...this.queryParams,
      });
    },
    search() {
      this.selectData({
        ...this.queryParams,
      });
    },
    reset() {
      // 取消选中
      this.selectedRowKeys = [];
      // 重置分页
      this.$refs.TableInfo.pagination.current = this.pagination.defaultCurrent;
      if (this.paginationInfo) {
        this.paginationInfo.current = this.pagination.defaultCurrent;
        this.paginationInfo.pageSize = this.pagination.defaultPageSize;
      }
      this.paginationInfo = null;
      // 重置查询参数
      this.queryParams = { projectId: "", isTaskService: 1 };
      this.fetch();
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      return false;
    },
    handleUpload() {
      const { fileList } = this;
      const formData = new FormData();
      formData.append("file", fileList[0]);
      this.uploading = true;
      this.$upload("task/import", formData)
        .then((r) => {
          let data = r.data.data;
          if (data.data != undefined && data.data.length) {
            this.fetch();
          }
          this.importData = data.data == undefined ? [] : data.data;
          this.errors = data.error == undefined ? [] : data.error;
          this.times = data.time / 1000;
          this.uploading = false;
          this.fileList = [];
          this.importResultVisible = true;
        })
        .catch((r) => {
          console.error(r);
          this.uploading = false;
          this.fileList = [];
        });
    },
    handleTableChange(pagination, filters, sorter) {
      this.paginationInfo = pagination;
      this.fetch({
        ...this.queryParams,
      });
    },
    fetch(params = {}) {
      this.loading = true;
      if (this.paginationInfo) {
        // 如果分页信息不为空，则设置表格当前第几页，每页条数，并设置查询分页参数
        this.$refs.TableInfo.pagination.current = this.paginationInfo.current;
        this.$refs.TableInfo.pagination.pageSize = this.paginationInfo.pageSize;
        params.pageSize = this.paginationInfo.pageSize;
        params.pageNum = this.paginationInfo.current;
      } else {
        // 如果分页信息为空，则设置为默认值
        params.pageSize = this.pagination.defaultPageSize;
        params.pageNum = this.pagination.defaultCurrent;
      }
      params.isTaskService = 1
      this.$get("task", {
        ...params,
      }).then((r) => {
        let data = r.data;
        const pagination = { ...this.pagination };
        pagination.total = data.total;
        this.loading = false;
        this.dataSource = data.rows;
        this.pagination = pagination;
      });
    },
    selectData(params = {}) {
      this.loading = true;
      // 如果分页信息为空，则设置为默认值
      this.$refs.TableInfo.pagination.current = this.pagination.defaultCurrent;
      this.$refs.TableInfo.pagination.pageSize = this.pagination.defaultPageSize;
      params.pageSize = this.pagination.defaultPageSize;
      params.pageNum = this.pagination.defaultCurrent;
      this.$get("task", {
        ...params,
      }).then((r) => {
        let data = r.data;
        const pagination = { ...this.pagination };
        pagination.total = data.total;
        this.loading = false;
        this.dataSource = data.rows;
        this.pagination = pagination;
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../utils/Common.less";
</style>